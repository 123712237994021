import { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../ui-elements/Main/MainTheme';
import { useClickOutside } from '@/helpers/app/hooks';
import { UserInfoContext } from '../user/user-info';
import { useDispatch } from 'react-redux';
import { useViewport } from '@/lib/layout/dimensions';
import { ConfirmModal } from '../shared/ConfirmModal';
import { SosInfoContext } from '../sos/sos-info';

export const DownTime = () => {
  const isMobile = useViewport((width) => width < 600, 500);
  const ref = useRef();
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const { systemDownMessage } = useContext(SosInfoContext);
  useClickOutside(ref, setToggle);

  const contentText = 
    <div>
      {systemDownMessage.split('\n').map((line, index) => (
        <>
          {line}
          <br />
        </>
      ))}
    </div>
  ;
  const handleClickStatus = (e) => {
    e.stopPropagation();
    setToggle(false);
    window.open('https://status.avochato.com/', '_blank', 'noopener,noreferrer');
  };

  return (
    <div ref={ref}>
      <Container
        $isMobile={isMobile}
        ref={ref}
        className="downtime-inner popper"
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        <span className="info-hover alert-icon" ></span>
        {!isMobile && 'Incident in progress'}
      </Container>
      {toggle && (
        <ConfirmModal
          handleConfirm={handleClickStatus}
          handleClose={() => setToggle(false)}
          confirmText="View Status"
          cancelText="Close"
          header="Incident In Progress"
          question={contentText}
          width="600px"
          successMessage=""
          failureMessage=""
        />
      )}
    </div>
  );
};

const Container = styled.div<{
  isMobile: boolean;
}>`
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  background: #FFF1E6;
  padding: ${({ $isMobile }) => ($isMobile ? '10px' : '5px')};
  height: 61px;
  width: ${({ $isMobile }) => ($isMobile ? '50px' : '260px')};
  border: 1px solid rgba(224, 198, 183, 0.2);
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 800 !important;
  font-size: 16px;
  span {
    cursor: pointer;
    background-color: ${colors.orange};
    border-radius: 50%;
    padding: ${({ $isMobile }) => ($isMobile ? '0 11px' : '0 12px')};
    color: white;
    font-weight: 700 !important;
    font-size: ${({ $isMobile }) => ($isMobile ? '21px' : '24px')};
    margin-right: ${({ $isMobile }) => ($isMobile ? '0' : '10px')};
    &::before {
      content:  "!";
    }
  }
`;
