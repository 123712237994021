import { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../ui-elements/Main/MainTheme';
import { useClickOutside } from '@/helpers/app/hooks';
import { useViewport } from '@/lib/layout/dimensions';
import { useNavigationActions } from '@/lib/navigation';
import { AccountInfoContext } from '../account/account-info';
import { OrgInfoContext } from '../organization/org-info';
import { Text } from '../ui-elements/Main/Text';
import { useHover } from '@/helpers/app/hooks';

export const ChurnedHeader = () => {
  const isMobile = useViewport((width) => width < 600, 500);
  const { subdomain } = useContext(AccountInfoContext);
  const ref = useRef();
  const hoverRef = useRef();
  const [toggle, setToggle] = useState(false);
  const [hover, setHover] = useState(false);
  
  const { navigate } = useNavigationActions();
  useClickOutside(ref, setToggle);
  useHover(hoverRef, setHover);

  const handleSupportClick = (e) => {
    e.stopPropagation();
    navigate({ path: `${window.location.origin}/accounts/${subdomain}/support`, params: {} });
  };

  return (
    <div ref={ref}>
      <Container
        ref={hoverRef}
        className="downtime-inner popper"
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        Your account is not active.
        <span className="info-hover alert-icon" ></span>
        <Button
          type="button"
          className="button bright-green md normal center"
          onClick={handleSupportClick}
        >
         Contact Support
        </Button>
      </Container>
      {!isMobile && hover && (
        <Tooltip
          display='flex'
          fontSize="14px"
          color={colors.white}
        >
          Account Cancelled
          <br />
          Your account is in an inactive state.
        </Tooltip>
      )}
    </div>  
  );
};

const Container = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  background: rgba(230, 0, 0, 0.13);
  padding: 5px 10px;
  height: 41px;
  border: 1px solid #E8A9A9;;
  border-radius: 50px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600 !important;
`;

const Button = styled.button`
  position: relative;
  cursor: pointer;
  margin: 10px;
  margin-right: 0;
  background: #e74500;
  padding: 2px 10px;
  height: 30px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-family: Montserrat, sans-serif !important;
  color: #fff;
  font-weight: 600 !important;
  text-transform: none;
  letter-spacing: normal;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
`;

const Tooltip = styled(Text)`
  background: black;
  padding: 12px 20px;
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
  min-width: fit-content;
  right: 45%;
`;