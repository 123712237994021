import { createContext } from 'react';

export interface SosInfoProps {
  isDown: boolean,
  systemDownMessage: string,
}

export const SosInfoContext = createContext<SosInfoProps>({
  isDown: null,
  systemDownMessage: null,
});