import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '@/redux/app/store';
import { Provider } from 'react-redux';
import { Root } from './subcomponents/header/Root';
import { UserInfoProps, UserInfoProvider } from './subcomponents/user/user-info';
import { AccountInfo, AccountInfoContext } from './subcomponents/account/account-info';
import { OrgInfoProps, OrgInfoContext } from './subcomponents/organization/org-info';
import { SosInfoProps, SosInfoContext } from './subcomponents/sos/sos-info';
import { DevSetup } from '@/redux/app/dev-setup';

if (process.env.NODE_ENV === 'development') window.devSetup ||= new DevSetup();

interface HeaderProps {
  userInfo: UserInfoProps,
  accountInfo: AccountInfo,
  orgInfo: OrgInfoProps,
  sosInfo: SosInfoProps,
  path: string;
  params: Record<string, string>;
}

export const Header = ({
  userInfo,
  accountInfo,
  orgInfo,
  sosInfo,
}: HeaderProps) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SosInfoContext.Provider value={sosInfo}>
          <UserInfoProvider value={userInfo}>
            <AccountInfoContext.Provider value={accountInfo}>
              <OrgInfoContext.Provider value={orgInfo}>
                <Root />
              </OrgInfoContext.Provider>
            </AccountInfoContext.Provider>
          </UserInfoProvider>
        </SosInfoContext.Provider>
      </PersistGate>
    </Provider>
  )
}

export default Header;