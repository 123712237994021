import { useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { colors } from '../ui-elements/Main/MainTheme';
import { useClickOutside } from '@/helpers/app/hooks';
import { UserInfoContext } from '../user/user-info';
import { useViewport } from '@/lib/layout/dimensions';
import { useNavigationActions } from '@/lib/navigation';
import { AccountInfoContext } from '../account/account-info';
import { OrgInfoContext } from '../organization/org-info';

export const TrialHeader = () => {
  const isMobile = useViewport((width) => width < 600, 500);
  const { isTrial, subdomain } = useContext(AccountInfoContext);
  const ref = useRef();
  const [toggle, setToggle] = useState(false);
  useClickOutside(ref, setToggle);
  const { trialEndDate } = useContext(OrgInfoContext);
  const { navigate } = useNavigationActions();
  const now = new Date();
  const trialEnd = new Date(trialEndDate);
  const trialDaysLeft = Math.max(
    0,
    Math.ceil((trialEnd.getTime() - now.getTime()) / (1000 * 60 * 60 * 24))
  );

  const handleUpgradeClick = (e) => {
    e.stopPropagation();
    navigate({ path: `${window.location.origin}/accounts/${subdomain}/billing`, params: {} });
  };

  return (
    <div ref={ref}>
      <Container
        className="downtime-inner popper"
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        {trialDaysLeft <= 0 ? 'Your trial has ended.' : `Your trial ends in ${trialDaysLeft} days.`}
        <span className="info-hover alert-icon" ></span>
        <Button
          type="button"
          className="button bright-green md normal center"
          onClick={handleUpgradeClick}
        >
         Upgrade
        </Button>
      </Container>
    </div>  
  );
};

const Container = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  background: #FBF8F3;
  padding: 5px 10px;
  height: 41px;
  border: 1px solid #DAD7D6;
  border-radius: 50px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600 !important;
`;

const Button = styled.button`
  position: relative;
  cursor: pointer;
  margin: 10px;
  margin-right: 0;
  background: #19BE51;
  padding: 2px 10px;
  height: 30px;
  width: 100px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-family: Montserrat, sans-serif !important;
  color: #fff;
  font-weight: 600 !important;
  text-transform: none;
  letter-spacing: normal;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
`;

