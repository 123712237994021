import styled from 'styled-components';
import { X } from 'react-feather';
import { colors } from '../ui-elements/Main/MainTheme';
import { Flex } from './Flex';

interface CloseButtonProps {
  handleClose: () => void;
  theme?: 'default' | 'colorContrast' | 'red' | 'transparent' | 'white';
  size?: number;
  [key: string]: any;
}

const THEMES = {
  default: {
    color: colors.black,
    background: colors.anotherGray,
    padding: '6px',
    borderRadius: '8px',
  },
  colorContrast: {
    color: colors.black,
    background: colors.white,
    padding: '6px',
    borderRadius: '8px',
  },
  red: {
    color: colors.red,
    background: null,
    padding: null,
    borderRadius: null,
  },
  transparent: {
    color: colors.black,
    background: null,
    padding: null,
    borderRadius: null,
  },
  white: {
    color: colors.white,
    background: null,
    padding: null,
    borderRadius: null,
  }
};

export const CloseButton = ({
  handleClose,
  size = 20,
  theme = 'default',
  ...rest
}: CloseButtonProps) => (
  <ImgContainer
    role="button"
    tabIndex={0}
    $theme={theme}
    onClick={handleClose}
    {...rest}
  >
    <X size={size} color={THEMES[theme].color} />
  </ImgContainer>
);

const ImgContainer = styled(Flex)<{
  theme?: string;
}>`
  cursor: pointer;
  z-index: 1;
  background: ${({ $theme }) => THEMES[$theme]?.background};
  padding: ${({ $theme }) => THEMES[$theme]?.padding};
  border-radius: ${({ $theme }) => THEMES[$theme]?.borderRadius};
`;