import React from 'react';
import styled from 'styled-components';
import { ActionStatus } from 'thunkless';
import { UILoader } from '../ui-elements/UILoader';
import { CloseButton } from './CloseButton';
import somethingWrong from '../../../../assets/images/static_pages/something_went_wrong.svg';
import successCheckmark from '../../../../assets/images/static_pages/success_check.svg';
import { Text } from '../ui-elements/Main/Text';

import { FlexCenter, FlexJustifyEnd, FlexColumn, FlexColumnCenter, FlexColumnAlignCenter, FlexJustifyBetween } from './Flex';

export interface ConfirmModalProps {
  width?: string;
  height?: string;
  handleClose: (e?: any) => void;
  handleConfirm: (any) => void;
  header?: string;
  status?: ActionStatus;
  content?: string | React.ReactNode;
  question?: string | React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  buttonColor?: string;
  successComponent?: string | React.ReactNode;
  successMessage: string;
  failureComponent?: string | React.ReactNode;
  failureMessage: string;
  isDisabled?: boolean;
  isSelection?: boolean;
  modalRef?: React.MutableRefObject<any>;
}

export const ConfirmModal = ({
  width = '500px',
  height = 'auto',
  handleClose,
  handleConfirm,
  header = '',
  status,
  question = '',
  content = '',
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  buttonColor = 'bright-green',
  successComponent = <img src={successCheckmark} alt="success_checkmark" />,
  successMessage,
  failureComponent = <img src={somethingWrong} alt="something_wrong" style={{ height: '100px' }} />,
  failureMessage,
  isDisabled = false,
  isSelection,
  modalRef = null,
}: ConfirmModalProps) => (
  <ModalContainerWrap className="avo-ui-3">
    <ModalContainer ref={modalRef}>
      <Modal $width={width} $height={height} $status={status}>
        {status === ActionStatus.BUSY && <UILoader />}
        {status === ActionStatus.FAILURE && (
          <ResponseContainer>
            {failureComponent}
            <P>{failureMessage}</P>
          </ResponseContainer>
        )}
        {status === ActionStatus.SUCCESS && (
          <ResponseContainer>
            {successComponent}
            <P>{successMessage}</P>
            <button
              type="button"
              className={`button md mt-10 ${buttonColor}`}
              onClick={handleClose}
            >
              Ok
            </button>
          </ResponseContainer>
        )}
        {!status && (
          <>
            <FlexColumn style={{ width: '100%' }}>
              {!isSelection && (
                <FlexJustifyEnd>
                  <CloseButton handleClose={handleClose} style={{ minWidth: '30px' }} />
                </FlexJustifyEnd>
              )}
              <FlexJustifyBetween style={{ alignItems: 'flex-start' }}>
                <Text
                  fontSize="24px !important"
                  fontWeight="800"
                  overflowWrap="break-word"
                >
                  {header}
                </Text>
              </FlexJustifyBetween>
              <br />
              <p>
                {question}
                <br />
                {content}
              </p>
            </FlexColumn>
            <ButtonContainer>
              <button
                type="button"
                className="button pale-green md mr-10"
                onClick={handleClose}
              >
                {cancelText}
              </button>
              {!isSelection && (
                <button
                  type="button"
                  className={`button md ${buttonColor}`}
                  onClick={handleConfirm}
                  disabled={isDisabled}
                >
                  {confirmText}
                </button>
              )}
            </ButtonContainer>
          </>
        )}
      </Modal>
    </ModalContainer>
  </ModalContainerWrap>
);

const ResponseContainer = styled(FlexColumnCenter)`
  height: 100%;
  text-align: center;
`;
const P = styled.p`
  padding-top: 15px;
`;
const ButtonContainer = styled(FlexJustifyEnd)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
`;
const ModalContainerWrap = styled(FlexCenter)`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1001;
  display: flex;
`;

const ModalContainer = styled(FlexCenter)`
  border-radius: 16px;
  background-color: #FFFFFF;
  max-width: 90%;
`;

type ModalProps = {
  width: string;
  height: string;
  status: ActionStatus;
};

const Modal = styled(FlexColumnAlignCenter)<ModalProps>`
  position: relative;
  justify-content: space-between;
  ${({ $status }) => !$status && 'align-items: flex-start'};
  ${({ $status }) => $status === ActionStatus.BUSY && `
    justify-content: center;
    align-items: center;
  `};
  flex-direction: column;
  width: ${({ $width }) => $width || 'auto'};
  height: ${({ $height }) => $height || 'auto'};
  padding: 25px 32px;
`;
