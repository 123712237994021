import { createContext } from 'react';

export interface OrgInfoProps {
  autoFill: boolean;
  balanceRemaining: number;
  hashId: string;
  trialStartDate: any;
  trialEndDate: any;
  isChurned: boolean;
}

export const OrgInfoContext = createContext<OrgInfoProps>({
  autoFill: null,
  balanceRemaining: null,
  hashId: null,
  trialStartDate: null,
  trialEndDate: null,
  isChurned: null,
});
